<template>
  <v-main class="main-content bg-body">
    <div
      v-if="$store.state.breadcrumbs.length"
      class="container"
    >
      <v-breadcrumbs
        :items="$store.state.breadcrumbs"
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-main>
</template>

<script>
  export default {
    name: 'BaseView',
  }
</script>
<style lang="scss" scoped>
  .main-content {
    padding: 0px 0px 0px !important;
  }
</style>
